var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      [
        _c("p-checkbox", {
          model: {
            value: _vm.task.done,
            callback: function ($$v) {
              _vm.$set(_vm.task, "done", $$v)
            },
            expression: "task.done",
          },
        }),
      ],
      1
    ),
    _c("td", { staticClass: "img-row" }, [
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { staticClass: "img-raised", attrs: { src: _vm.task.img } }),
      ]),
    ]),
    _c("td", { staticClass: "text-left" }, [_vm._v(_vm._s(_vm.task.title))]),
    _c(
      "td",
      { staticClass: "td-actions text-right" },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "Edit", placement: "top" } },
          [
            _c(
              "p-button",
              {
                staticClass: "btn-icon-mini btn-neutral",
                attrs: { type: "info", round: "", icon: "" },
                on: { click: _vm.handleEditClick },
              },
              [_c("i", { staticClass: "nc-icon nc-ruler-pencil" })]
            ),
          ],
          1
        ),
        _c(
          "el-tooltip",
          { attrs: { placement: "top", content: "Remove" } },
          [
            _c(
              "p-button",
              {
                staticClass: "btn-icon-mini btn-neutral",
                attrs: { type: "danger", round: "", icon: "" },
                on: { click: _vm.handleDeleteClick },
              },
              [_c("i", { staticClass: "nc-icon nc-simple-remove" })]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }