var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    [
      _c("template", { slot: "header" }, [
        _c("h4", { staticClass: "card-title" }, [_vm._v("Tasks")]),
        _c("p", { staticClass: "category" }, [_vm._v("Backend development")]),
      ]),
      _c("div", { staticClass: "table-full-width table-tasks" }, [
        _c("table", { staticClass: "table" }, [
          _c(
            "tbody",
            _vm._l(_vm.tasks, function (task, index) {
              return _c("task", {
                key: task.title,
                attrs: { task: task, index: index },
                on: {
                  "on-edit": _vm.handleTaskEdit,
                  "on-delete": _vm.handleTaskDelete,
                },
              })
            }),
            1
          ),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c("hr"),
        _c("div", { staticClass: "stats" }, [
          _c("i", { staticClass: "fa fa-history" }),
          _vm._v(" Updated 3 minutes ago\n    "),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }