var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-6 col-md-12 text-center" },
        [
          _c("task-list"),
          _c(
            "card",
            [
              _c(
                "div",
                {
                  staticClass: "card-text",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Employees Stats"),
                  ]),
                  _c("p", { staticClass: "card-category" }, [
                    _vm._v("New employees on 15th September, 2016"),
                  ]),
                ]
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.employeeData,
                    "header-row-class-name": "text-warning",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      width: "50px",
                      align: "left",
                      prop: "id",
                      label: "ID",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "name", label: "NAME" },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "salary", label: "SALARY" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "country",
                      label: "COUNTRY",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-lg-6 col-md-12" },
        [
          _c(
            "time-line",
            { attrs: { type: "simple" } },
            [
              _c(
                "time-line-item",
                {
                  staticClass: "timeline-inverted",
                  attrs: {
                    badgeType: "danger",
                    badgeIcon: "nc-icon nc-single-copy-04",
                  },
                },
                [
                  _c(
                    "badge",
                    {
                      attrs: { slot: "header", type: "danger" },
                      slot: "header",
                    },
                    [_vm._v("Some title")]
                  ),
                  _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      "\n            Wifey made the best Father's Day meal ever. So thankful so happy so blessed. Thank you for making my\n            family\n            We just had fun with the “future” theme !!! It was a fun night all together ... The always rude Kanye Show\n            at 2am Sold Out Famous viewing @ Figueroa and 12th in downtown."
                    ),
                  ]),
                  _c("h6", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _c("i", { staticClass: "fa fa-block-o" }),
                    _vm._v(
                      "\n            11 hours ago via Twitter\n          "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "time-line-item",
                {
                  staticClass: "timeline-inverted",
                  attrs: {
                    badgeType: "success",
                    badgeIcon: "nc-icon nc-sun-fog-29",
                  },
                },
                [
                  _c(
                    "badge",
                    {
                      attrs: { slot: "header", type: "success" },
                      slot: "header",
                    },
                    [_vm._v("Another Title")]
                  ),
                  _c("p", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v(
                      "\n            Thank God for the support of my wife and real friends. I also wanted to point out that it’s the first\n            album\n            to go number 1 off of streaming!!! I love you Ellen and also my number one design rule of anything I do\n            from\n            shoes to music to homes is that Kim has to like it...."
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "time-line-item",
                {
                  staticClass: "timeline-inverted",
                  attrs: { badgeType: "info", badgeIcon: "nc-icon nc-world-2" },
                },
                [
                  _c(
                    "badge",
                    { attrs: { slot: "header", type: "info" }, slot: "header" },
                    [_vm._v("Another Title")]
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [
                      _c("p", [
                        _vm._v(
                          "\n              Called I Miss the Old Kanye That’s all it was Kanye And I love you like Kanye loves Kanye Famous viewing\n              @\n              Figueroa and 12th in downtown LA 11:10PM"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              What if Kanye made a song about Kanye Royère doesn't make a Polar bear bed but the Polar bear couch is\n              my\n              favorite piece of furniture we own It wasn’t any Kanyes Set on his goals Kanye"
                        ),
                      ]),
                      _c("hr"),
                      _c(
                        "drop-down",
                        {
                          staticClass: "btn-group",
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function ({ isOpen }) {
                                return _c(
                                  "p-button",
                                  {
                                    staticClass: "dropdown-toggle",
                                    attrs: {
                                      type: "info",
                                      round: "",
                                      "aria-expanded": isOpen,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "nc-icon nc-settings",
                                    }),
                                    _vm._v("  \n              "),
                                  ]
                                )
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                            },
                            [_vm._v("Action")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                            },
                            [_vm._v("Another action")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" },
                            },
                            [_vm._v("Something else here")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6" },
            [
              _c(
                "card",
                { attrs: { type: "pricing" } },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "card-category",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v(" Alpha Pack")]
                  ),
                  _c("div", { staticClass: "card-icon icon-primary" }, [
                    _c("i", { staticClass: "nc-icon nc-spaceship" }),
                  ]),
                  _c("h3", { staticClass: "card-title" }, [_vm._v("69$")]),
                  _c("ul", [
                    _c("li", [_vm._v("Working materials in EPS")]),
                    _c("li", [_vm._v("6 months access to the library")]),
                  ]),
                  _c(
                    "p-button",
                    {
                      attrs: { slot: "footer", type: "primary", round: "" },
                      slot: "footer",
                    },
                    [_vm._v("Add to Cart")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-6" },
            [
              _c(
                "card",
                { staticClass: "card-plain", attrs: { type: "pricing" } },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "card-category",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [_vm._v(" Bravo Pack")]
                  ),
                  _c("div", { staticClass: "card-icon icon-warning" }, [
                    _c("i", { staticClass: "nc-icon nc-shop" }),
                  ]),
                  _c("h3", { staticClass: "card-title" }, [_vm._v("10$")]),
                  _c("ul", [
                    _c("li", [_vm._v("Complete documentation")]),
                    _c("li", [_vm._v("Working materials in Sketch")]),
                  ]),
                  _c(
                    "p-button",
                    {
                      staticClass: "btn-neutral",
                      attrs: { slot: "footer", type: "warning", round: "" },
                      slot: "footer",
                    },
                    [_vm._v("Add to Cart")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c(
            "card",
            { attrs: { type: "testimonial" } },
            [
              _c("div", { staticClass: "icon icon-primary" }, [
                _c("i", { staticClass: "fa fa-quote-right" }),
              ]),
              _c("p", { staticClass: "card-description" }, [
                _vm._v(
                  "\n          The networking at Web Summit is like no other European tech conference.\n        "
                ),
              ]),
              _c("template", { slot: "footer" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Gina Andrew"),
                ]),
                _c("h6", { staticClass: "card-category" }, [
                  _vm._v("@ginaandrew"),
                ]),
                _c("div", { staticClass: "card-avatar" }, [
                  _c("a", { attrs: { href: "#pablo" } }, [
                    _c("img", {
                      staticClass: "img",
                      attrs: { src: "static/img/faces/joe-gardner-2.jpg" },
                    }),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }